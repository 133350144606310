import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'

interface Props {

}

export default function Home2({ }: Props): ReactElement {
  return (
    <div className="wrapper">
      <div className="hero row">
        <div className="col-md-5 col-xs-12">
          <h1>
            Venture Builder
        </h1>
          <p className="subhead">Creating new business.</p>
          <p className="subhead">Another sentence.</p>
          <p className="subhead">Another sentence.</p>
          {/* <Link to="" className="button marginbottom">Work with me</Link> */}

        </div>
        <div className="profile-pic col-md-6 col-xs-12">
       
       <p>Not built with Spline</p>
        
        </div>
      </div>
    </div>
  )
}
