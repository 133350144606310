import React, { ReactElement } from 'react'

interface Props {

}

export default function Footer({ }: Props): ReactElement {
  return (
    <footer>
      <div className="footer">
        <ul>
          <li>&copy; 2023</li>
          <li><a href="mailto:christophe@vaneerdt.be" target="_blank">E-mail</a></li>
          <li><a href="tel:+32 479 70 63 42" target="_blank">+32 479 70 63 42</a></li>
          <li><a href="https://www.linkedin.com/in/cvaneerdt/" target="_blank">LinkedIn</a></li>
          {/* <li><a href="Christophe van Eerdt CV 2021.pdf" target="_blank">CV</a></li> */}
          <li>VAT BE 06677 95 609</li>
        </ul>
      </div>
    </footer>
  )
}
