import React, { ReactElement } from "react";
import FaceImage from "./FaceImage";

interface Props {

}

export default function About({ }: Props): ReactElement {
  return (
    <div>
      <div className="wrapper">
        <div className="hero row">
          <div className="col-md-7 col-xs-12">
            <h1>
              About me
        </h1>
            <p className="subheading">Values</p>
            <p className="text">
              My brain starts tingling when I’m in a room and I can just feel when people working together get excited about
              opportunities. I like to keep that buzz going by backing those opportunities
              up
              with real test data so that
              we
              can confidently build and grow desirable and valuable products.
        </p>
            <p className="text">
              I’m convinced that unique value is made through collaboration between curious and driven people who apply
          solid design methodology and a pragmatic <i>anything is possible</i> mindset. In these environments, where
          progress,
          failures and results are fast-paced, I do my best work.
        </p>
            <p className="text">
              At work I want to make a difference by creating products that matter. To do this I believe that
              dedication
              combined with honesty and transparency with the people I work with will always benefit personal and business
              growth. Let's grab a coffee if you think we'd fit together <span className="emoji">☕</span>.
        </p>
            <p className="subheading">Background</p>
            <p className="text">Thanks to great online communities and mentors from around the world, I was able to start
            freelancing in web design {"&"} development by the age of 16. My curiosity in technology and startups further
            inspired my choice for an academic career in which I developed my creativity, business sense and technological
            skills.
        </p>
            <p className="text">My bachelor's degree in interaction design taught me empathy, human centered design,
          prototyping, iteration and a <i>fail fast</i> attitude. My masters degrees in corporate communication and
          information
          management further equipped me to take ideas and turn them into compelling, feasible and viable products.
        </p>
            <p className="text">Because of my multidisciplinary background and work experience in strategy, UX and innovation,
            I’m now comfortable in taking on complex innovation and transformation challenges as a product strategist and
            designer. Contact me to talk about your challenge <span className="emoji">💪</span>.
        </p>
            <p className="subheading">Life</p>
            <p className="text">Work-life balance is important to me. I believe it’s possible to do what you love for a job. Crazy, right? After work I enjoy being with friends. As an ENTP-personality, I get most of my energy and drive from social engagement. When I’m alone I
            enjoy keeping up with technology, startups and my favorite youtubers.
        </p>
            <p className="text">
              Before COVID-19 I played squash religiously on Sundays, now this spot is reserved for mountain biking. I also
              try to go swimming, walking or cycling a few times a week. When we really, really feel like it, we roam free padel fields. Get in touch if you want to play a game or two <span className="emoji">😉</span>.
        </p>
          </div>
          <div className="col-md-3 col-md-offset-2 col-xs-12 about" id="contact">
            <FaceImage />
            <p className="sidenote text">Let's get on top of your business. Get in touch <span className="emoji">👇</span> .</p>
            <a className="button" href="tel:+32 479 70 63 42" target="_blank">+32 479 70 63 42</a>
            <a className="button" href="mailto:christophe@vaneerdt.be" target="_blank">E-mail</a>
            <a className="button" href="https://www.linkedin.com/in/cvaneerdt/" target="_blank">LinkedIn</a>
            <a className="button" href="./assets/Christophe van Eerdt CV 2021.pdf" target="_blank">Download CV</a>
          </div>
        </div>
      </div>
    </div>
  )
}
