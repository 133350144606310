import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

interface Props {

}

export default function Header({ }: Props): ReactElement {
  return (
    <header className="site-header">
      <div className="wrapper site-header__wrapper">

        <div className="brand">
          <Link to="/">Christophe&nbsp;<span>van Eerdt👋</span></Link>
        </div>
        <nav className="flex nav">
          <ul className="nav__wrapper">
          <li className="nav__item"><HashLink to="/home2">Home 2</HashLink></li>
            {/* <li className="nav__item"><HashLink to="/#work">Work</HashLink></li>
            <li className="nav__item"><Link to="/about">About</Link></li> */}
          </ul>
        </nav>
      </div>
    </header>
  )
}
