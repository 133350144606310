import React from 'react';
import Header from './Header';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import About from './About';
import Home from './Home';
import Home2 from './Home2';
import Footer from './Footer';
import Strategy from './Strategy';
import UX from './UX';
import Innovation from './Innovation';

function App() {
  return (
    <>
      <Header />
      <Switch>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/home2">
          <Home2 />
        </Route>
        <Route exact path="/cases/strategy">
          <Strategy />
        </Route>
        <Route exact path="/cases/ux">
          <UX />
        </Route>
        <Route exact path="/cases/innovation">
          <Innovation />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
        <Redirect from="*" to="/" />
      </Switch>
      <Footer />
    </>
  );
}

export default App;
